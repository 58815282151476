<template>
  <div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <h4 class="d-flex align-items-center">
        <font-awesome-icon icon="fas fa-laptop-house" />
        <span class="ml-1">公司資料</span>
      </h4>
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="pt-3" @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <!-- company_name_cn -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="公司名稱（中文）"
                rules="required"
              >
                <b-form-group
                  label="公司名稱（中文）："
                  label-for="company_name_cn"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-input
                    id="company_name_cn"
                    disabled
                    v-model="supplierData.company_name_cn"
                    :state="getValidationState(validationContext)"
                    placeholder="輸入公司名稱（中文）"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- company_name_en -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="公司名稱（英文）"
                rules="required"
              >
                <b-form-group
                  label="公司名稱（英文）："
                  label-for="company_name_en"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-input
                    id="company_name_en"
                    v-model="supplierData.company_name_en"
                    disabled
                    :state="getValidationState(validationContext)"
                    placeholder="輸入公司名稱（英文）"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <hr />
            <!-- ci_number -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="公司註冊證書號碼 (CI) "
                rules="required"
              >
                <b-form-group
                  label="公司註冊證書號碼 (CI) ："
                  label-for="ci_number"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-input
                    id="ci_number"
                    v-model="supplierData.ci_number"
                    disabled
                    :state="getValidationState(validationContext)"
                    placeholder="輸入公司註冊證書號碼"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- email -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="電郵"
                rules="required|email"
              >
                <b-form-group
                  label="電郵："
                  label-for="c_email"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-input
                    id="c_email"
                    v-model="supplierData.email"
                    disabled
                    :state="getValidationState(validationContext)"
                    placeholder="輸入電郵"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <hr />
            <!-- mobile -->
            <b-col cols="12" md="6">
              <b-form-group
                label="電話："
                label-for="c_mobile"
                label-cols-md="3"
              >
                <b-form-input
                  id="c_mobile"
                  disabled
                  v-model="supplierData.mobile"
                  placeholder="輸入電話"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="逾期還款時間"
                rules="required"
              >
                <b-form-group
                  label="逾期還款時間："
                  label-for=""
                  label-cols-md="3"
                  class="required"
                >
                  <flat-pickr
                    v-model="supplierData.late_time"
                    disabled
                    class="form-control"
                    :config="{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i',
                    }"
                    placeholder="輸入逾期還款時間"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <hr />

            <!-- address -->
            <b-col cols="12" md="12">
              <div
                class="d-flex flex-wrap flex-md-nowrap align-items-center full_input"
              >
                <label>地址：</label>
                <div class="input_wrap">
                  <b-form-input
                    id="c_address"
                    v-model="supplierData.address"
                    disabled
                    placeholder="輸入地址"
                  />
                </div>
              </div>
            </b-col>
            <hr />

            <!-- contact_name -->
            <b-col cols="12" md="6">
              <b-form-group
                label="聯絡人姓名："
                label-for="contact_name"
                label-cols-md="3"
              >
                <b-form-input
                  id="contact_name"
                  v-model="supplierData.contact_name"
                  disabled
                  placeholder="輸入聯絡人姓名"
                />
              </b-form-group>
            </b-col>

            <!-- mobile -->
            <b-col cols="12" md="6">
              <b-form-group
                label="放債人號碼："
                label-for="moneylender_number"
                label-cols-md="3"
              >
                <b-form-input
                  id="moneylender_number"
                  disabled
                  v-model="supplierData.moneylender_number"
                  placeholder="輸入放債人號碼"
                />
              </b-form-group>
            </b-col>
            <hr />
          </b-row>
          <div>
            <h4 class="d-flex pt-4 pb-2 align-items-center">
              <font-awesome-icon icon="fas fa-key" />
              <span class="ml-1">旗下帳戶</span>
            </h4>
            <div class="mb-1">
              <b-row
                v-for="(acc, index) in supplierData.childs"
                :key="`accs_${index}`"
                class="py-2"
              >
                <b-col cols="12">
                  <div class="d-flex px-1 mb-1">
                    <h4 class="color4">帳戶({{ index + 1 }})</h4>
                  </div>
                  <div class="d-flex border rounded">
                    <b-row class="flex-grow-1 p-2">
                      <!-- email -->
                      <b-col cols="12" md="6">
                        <validation-provider
                          #default="validationContext"
                          name="電郵"
                          :vid="`v-acc_email${index}`"
                          rules="required|email"
                        >
                          <b-form-group
                            label="電郵："
                            :label-for="`acc_email${index}`"
                            label-cols-md="3"
                            class="required"
                          >
                            <b-form-input
                              :id="`acc_email${index}`"
                              v-model="acc.email"
                              disabled
                              :state="getValidationState(validationContext)"
                              placeholder="輸入電郵"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- mobile -->
                      <b-col cols="12" md="6">
                        <b-form-group
                          label="電話："
                          :label-for="`acc_mobile${index}`"
                          label-cols-md="3"
                        >
                          <b-form-input
                            :id="`acc_mobile${index}`"
                            v-model="acc.mobile"
                            disabled
                            placeholder="輸入電話"
                          />
                        </b-form-group>
                      </b-col>

                      <hr />

                      <!-- address -->
                      <!-- <b-col cols="12" md="12">
                        <div
                          class="
                            d-flex
                            flex-wrap flex-md-nowrap
                            align-items-center
                            full_input
                          "
                        >
                          <label>地址：</label>
                          <div class="input_wrap">
                            <b-form-input
                              v-model="acc.address"
                              placeholder="輸入地址"
                            />
                          </div>
                        </div>
                      </b-col> 
                      <hr /> -->

                      <!-- contact_name -->
                      <b-col cols="12" md="6">
                        <validation-provider
                          #default="validationContext"
                          :vid="`v-acc_contact_name${index}`"
                          name="聯絡人姓名"
                          rules="required"
                        >
                          <b-form-group
                            label="聯絡人姓名："
                            :label-for="`contact_name${index}`"
                            label-cols-md="3"
                            class="required"
                          >
                            <b-form-input
                              :id="`contact_name${index}`"
                              v-model="acc.contact_name"
                              placeholder="輸入聯絡人姓名"
                              disabled
                              :state="getValidationState(validationContext)"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- contact_phone -->
                      <b-col cols="12" md="6">
                        <b-form-group
                          label="聯絡人電話："
                          :label-for="`acc_contact_phone${index}`"
                          label-cols-md="3"
                        >
                          <b-form-input
                            :id="`acc_contact_phone${index}`"
                            v-model="acc.contact_phone"
                            disabled
                            placeholder="輸入聯絡人電話"
                          />
                        </b-form-group>
                      </b-col>
                      <hr />

                      <!-- is_active -->
                      <b-col cols="12" md="6">
                        <b-form-group
                          label="狀態"
                          :label-for="`acc_is_active${index}`"
                          label-cols-md="3"
                        >
                          <b-form-checkbox
                            :checked="acc.is_active"
                            name="is_active"
                            switch
                            inline
                            v-model="acc.is_active"
                            value="1"
                            disabled
                            style="margin-top: 5px"
                          >
                            {{ acc.is_active ? "啓動" : "禁用" }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>

          <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
            <b-button v-if="false"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              提交
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              取消
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, email } from "@validations";

export default {
  data() {
    return {
      required,
      email,
      confirm_p: "",
      acc_password: [],
    };
  },
  directives: {
    Ripple,
  },
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BOverlay,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    hide() {
      // this.$router.replace("/suppliers");
      this.$router.back();
    },
    onSubmit() {
      // convert is_active to number

      if (this.supplierData.is_active === false)
        this.supplierData.is_active = 0;

      this.supplierData.is_active = Number(this.supplierData.is_active);

      this.supplierData.childs.forEach((item) => {
        if (item.is_active === false) {
          item.is_active = 0;
        }
        item.is_active = item.is_active * 1;
      });

      this.onSubmitFormA();
    },
    onSubmitFormA() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-supplier/addSupplier", this.supplierData)
            .then((response) => {
              this.loading = false;
              this.$store.dispatch("app/fetchCoinsBalance");
              this.$swal({
                text: response.data.message,
                icon: "success",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              this.$emit("refetch-data");

              if (this.supplierData.id == 0) {
                this.supplierData.id = response.data.id;
                this.redirect(response.data.id);
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },

    addItem() {
      this.supplierData.childs.push(
        JSON.parse(JSON.stringify({ id: 0, is_active: 1 }))
      );
      this.acc_password.push("");
    },
    removeItem(index, item) {
      // check number of record

      if (item.id > 0) {
        this.$swal({
          title: "你確定嗎?",
          text: "您的行動是最終的，您將無法檢索這記錄。",
          showCancelButton: true,
          confirmButtonText: "刪除",
          cancelButtonText: "取消",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$store
              .dispatch("app-supplier/deleteSupplierAccount", { id: item.id })
              .then((response) => {
                this.$swal({
                  text: response.data.message,
                  icon: "success",
                  confirmButtonText: "確定",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
                this.$emit("refetch-data");
              })
              .catch((error) => {
                this.$swal({
                  text: JSON.stringify(error.response.data.message),
                  icon: "error",
                  confirmButtonText: "確定",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
              });
          }
        });
      } else {
        this.supplierData.childs.splice(index, 1);
        this.acc_password.splice(index, 1);
      }
    },
  },

  props: {
    supplierData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const resetSupplierData = () => {
      props.supplierData = JSON.parse(JSON.stringify({ id: 0 }));
    };
    const loading = ref(false);

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetSupplierData);

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
.flatpickr-input[readonly] {
  background-color: #efefef;
}
</style>
