<template>
  <component :is="supplierData === undefined ? 'div' : 'b-card'">
    <b-alert variant="danger" :show="supplierData === undefined">
      <h4 class="alert-heading">獲取財務公司數據時出錯</h4>
      <div class="alert-body">
        找不到相關財務公司。請查看
      </div>
    </b-alert>
    <supplier-edit-tab-information v-if="supplierData"
      :supplier-data="supplierData"
      @refetch-data="refetchData"
      class="p-sm-2"
    />
  </component>
</template>

<script>
import { BCard, BAlert, BLink, BTabs, BTab  } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import supplierStoreModule from "../supplierStoreModule";
import SupplierEditTabInformation from "./SupplierEditTabInformation.vue";


export default {
  components: {
    SupplierEditTabInformation,
    BCard,
    BAlert,
    BTabs,
    BTab,
    BLink,
  },
  setup() {

    const SUPPLIER_APP_STORE_MODULE_NAME = "app-supplier";
    
    const supplierData = ref({})

    if (!store.hasModule(SUPPLIER_APP_STORE_MODULE_NAME))
      store.registerModule(SUPPLIER_APP_STORE_MODULE_NAME, supplierStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SUPPLIER_APP_STORE_MODULE_NAME))
        store.unregisterModule(SUPPLIER_APP_STORE_MODULE_NAME);
    });

    const refetchData = () => {
        store
          .dispatch("app-supplier/fetchSupplier")
          .then((response) => {
            supplierData.value = response.data.supplier;
          })
          .catch((error) => {
            console.log("error when fetching supplier", error);
            if (error.response.status === 404) {
              supplierData.value = undefined;
            }
          });
    };

    onMounted(() => {
      refetchData();
    });

    return {
      supplierData,
      refetchData,
    };
  },
};
</script>
